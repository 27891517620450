import request from '@/utils/request'

// user login
export function userLogin(data) {
    return request({
        url: "/api/base/user/login",
        method: "post",
        data
    })
}

// third login
export function thirdLogin(data) {
    return request({
        url: "/api/base/third/login",
        method: "post",
        data
    })
}
// third verified
export function thirdVerified(data) {
    return request({
        url: "/api/base/third/verified",
        method: "post",
        data
    })
}

// getTwitterAccount
export function getTwitterAccount(params) {
    return request({
        url: "/api/base/user/getTwitterAccount",
        method: "get",
        params
    })
}

// getAllPayInfo
export function getAllPayInfo(params) {
    return request({
        url: "/api/base/user/getAllPayInfo",
        method: "get",
        params
    })
}


// delTwitterAccount
export function delTwitterAccount(params) {
    return request({
        url: "/api/base/user/delTwitterAccount",
        method: "get",
        params
    })
}

// get twitter info
export function getTwitterInfo(data) {
    return request({
        url: "/api/base/third/getTwitterInfo",
        method: "get",
        data
    })
}
export function getTwitterRemainingNum(params) {
    return request({
        url: "/api/base/user/getTwitterRemainingNum",
        method: "get",
        params
    })
}
// get pay info
export function getPayInfo(data) {
    return request({
        url: "/api/base/third/getPayInfo",
        method: "get",
        data
    })
}


export function exportAccount(data) {
    return request({
      url: "/api/base/user/exportAccount",
      method: "post",
      data,
      responseType: 'blob' // 设置响应类型为blob
    });
  }

export function uploadAccount(formData) {
    // 调用上传 Excel 文件的方法，使用 MultipartFile 类型
    // 示例代码：
    return request({
      url: "/api/base/user/upload",
      method: "post",
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  export function bindAddress(formData) {
    return request({
      url: "/api/base/user/bindAddress",
      method: "post",
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

